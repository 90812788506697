<template>
  <!-- 商品列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 筛选 -->
                  <a-form-item>
                    <a-select v-model="queryParam.type" allow-clear placeholder="请选择状态">
                      <a-select-option :value="1">出售中</a-select-option>
                      <a-select-option :value="2">已售罄</a-select-option>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :md="6" :sm="24">
                  <!-- 商品搜索 -->
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入商品名称、关键字"
                      v-model="queryParam.keywords"
                      @pressEnter="searchTableData"
                    />
                  </a-form-item>
                </a-col>

                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button
                    style="margin-left: 8px"
                    @click="
                      () => {
                        queryParam = {}
                        searchTableData()
                      }
                    "
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 添加商品按钮 -->
          <div class="table-operator">
            <a-button type="primary" @click="batchCopyCommodity">批量复制商品</a-button>
          </div>
          <!-- 表格 -->
          <a-table
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 审核状态插槽 -->
            <span slot="checkResult" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : 'red'">
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>
            <!-- 审核备注插槽 -->
            <span slot="reason" slot-scope="text">
              {{ text }}
            </span>
            <!-- 商品图片插槽 -->
            <span slot="commodityImg" slot-scope="text, record">
              <img :src="record.coverImage" alt="" style="width: 68px; height: 68px; border-radius: 50%" />
            </span>
            <!-- 规格类型插槽 -->
            <span slot="speType" slot-scope="text">
              {{ text == 1 ? '单规格' : text == 2 ? '多规格' : '' }}
            </span>

            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="上架"
                un-checked-children="下架"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <!-- <a @click="showModal1(record)" v-if="record.speType == 2">绑定规格</a>
              <a-divider type="vertical" v-if="record.speType == 2" />
              <router-link :to="{ path: '/setPrice', query: { id: record.id } }" v-if="record.speType == 2"
                >设置价格
              </router-link>
              <a-divider type="vertical" v-if="record.speType == 2" />
              <router-link :to="{ path: '/editCommodity', query: { id: record.id } }">编辑</router-link>
              <a-divider type="vertical" /> -->
              <!--竖线-->
              <span v-if="record.result == 1">
                <a-divider type="vertical" />
                <a @click="approve({ id: record.id, result: '2' })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ id: record.id, result: '3' })">拒绝</a>
              </span>
              <a-divider type="vertical" />
              <a @click="$refs.detailForm.detail(record)"> 详情 </a>
              <a-divider type="vertical" />
              <!-- <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm> -->
              <router-link :to="{ path: '/commodityspec', query: { goodsId: record.id } }">查看评论</router-link>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      :width="1200"
      title="绑定规格"
      :visible="visible1"
      @cancel="handleCancel1"
      cancelText="关闭"
      :confirm-loading="confirmLoading1"
      :footer="null"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSpecForm.add(currentItem)">添加</a-button>
      </div>
      <a-table :columns="specColumns" :data-source="specDatas" :scroll="{ x: 800 }">
        <span slot="action" slot-scope="text, record">
          <a @click="showModal2(record)">添加规格属性</a>
          <a-divider type="vertical" />
          <a-popconfirm placement="topRight" title="确定删除该规格?" @confirm="() => confirmDeleteSpec(record)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <a-modal
      :width="1200"
      title="规格属性"
      :visible="visible2"
      :confirm-loading="confirmLoading2"
      :footer="null"
      @cancel="handleCancel2"
    >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="$refs.addSepcvalueForm.add(currentSpecItem, currentItem)">
          添加</a-button
        >
      </div>
      <a-table :columns="specValueColumns" :data-source="specValueDatas" :pagination="false" :scroll="{ x: 800 }">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"> <a-icon type="smile-o" /> Name </span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            placement="topRight"
            title="确定删除该规格属性?"
            @confirm="() => confirmDeleteSpecValue(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>
    <add-spec-form ref="addSpecForm" @ok="handleSpecOk" />
    <add-specvalue-form ref="addSepcvalueForm" @ok="handleSpecvalueOk" />
    <detail-form ref="detailForm" />
    <one-form ref="oneForm" @ok="getList"></one-form>
    <BatchCopyCommodity ref="batchCopyCommodity" @ok="refreshList"></BatchCopyCommodity>
  </div>
</template>

<script>
import {
  getCommodityList,
  querySpecListByCommodityId,
  querySpecValueListBySpcId,
  commodityDelete,
  deleteGoodsSpecValue,
  deleteGoodsSpec,
  goodsCheckResult,
} from '@/api/modular/mallLiving/commodity/commodityList'

import AddSpecForm from './addSpecForm.vue'
import AddSpecvalueForm from './addSpecvalueForm'
import detailForm from './detailForm.vue'
import oneForm from './oneForm.vue'
import BatchCopyCommodity from './batchCopyCommodity.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: '260px',
  },
  {
    title: '一级分类',
    dataIndex: 'parentGoodsCategoryName',
    key: 'parentGoodsCategoryName',
    align: 'center',
    width: '150px',
  },
  {
    title: '二级分类',
    dataIndex: 'goodsCategoryName',
    key: 'goodsCategoryName',
    align: 'center',
    width: '150px',
  },
  {
    title: '审核状态',
    dataIndex: 'result',
    width: '100px',
    align: 'center',
    scopedSlots: {
      customRender: 'checkResult',
    },
  },
  {
    title: '审核备注',
    key: 'reason',
    dataIndex: 'reason',
    scopedSlots: {
      customRender: 'reason',
    },
    align: 'center',
    width: '160px',
  },
  {
    title: '封面图',
    dataIndex: 'coverImage',
    key: 'coverImage',
    align: 'center',
    scopedSlots: {
      customRender: 'commodityImg',
    },
    width: '100px',
  },
  {
    title: '市场价',
    key: 'marketPrice',
    dataIndex: 'marketPrice',
    align: 'center',
    width: '160px',
  },
  {
    title: '销售量',
    key: 'sales',
    dataIndex: 'sales',
    align: 'center',
    width: '160px',
  },
  {
    title: '规格类型',
    key: 'speType',
    dataIndex: 'speType',
    align: 'center',
    scopedSlots: {
      customRender: 'speType',
    },
    width: '160px',
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    align: 'center',
    width: '86px',
  },
  {
    title: '商家昵称',
    key: 'storeName',
    dataIndex: 'storeName',
    align: 'center',
    width: '160px',
  },
  {
    title: '状态',
    key: 'delFlag',
    dataIndex: 'delFlag',
    scopedSlots: {
      customRender: 'delFlag',
    },
    align: 'center',
    width: '160px',
  },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
    align: 'center',
    width: '160px',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    width: '240px',
    key: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  components: {
    BatchCopyCommodity,
    oneForm,
    AddSpecForm,
    AddSpecvalueForm,
    detailForm,
  },
  data() {
    return {
      visible1: false,
      confirmLoading1: false,
      columns,
      currentItem: null,
      currentSpecItem: null,
      data: [],
      loading: false,
      confirmLoading2: false,
      visible2: false,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      classList: [], //顶部商品分类筛选数
      queryParam: {
        commodityClass: undefined, //商品分类
        commoditySearch: undefined, //商品搜索
      },
      specPage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specValuePage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specTotal: 0,
      specValueTotal: 0,
      specValueColumns: [
        {
          title: '规格属性名称',
          dataIndex: 'specValueName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specColumns: [
        {
          title: '规格名称',
          dataIndex: 'specName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specDatas: [],
      specValueDatas: [],
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectRowData) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectRowData.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    batchCopyCommodity() {
      if (!this.selectRowId.length) {
        return this.$message.info('请选择需要复制的商品')
      }
      this.$refs.batchCopyCommodity.show(this.selectRowId)
    },
    specPageChange(pageNo, pageSize) {
      this.specPage.pageNumber = pageNo
      this.specPage.pageSize = pageSize
      this.querySpecvalueListBySpecId()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    confirmDeleteSpecValue(record) {
      deleteGoodsSpecValue({
        id: record.goodsSpecValueId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格属性删除成功')
          this.querySpecvalueListBySpecId()
        }
      })
    },
    confirmDeleteSpec(record) {
      deleteGoodsSpec({
        id: record.goodsSpecId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格删除成功')
          this.findCommoditySpecList()
        }
      })
    },
    showModal2(record) {
      this.currentSpecItem = record
      this.querySpecvalueListBySpecId()
      this.visible2 = true
    },

    handleCancel1() {
      this.visible1 = false
    },

    handleCancel2(e) {
      this.visible2 = false
    },
    approve(params) {
      this.$confirm({
        title: '提示',
        content: '是否审核通过当前商品？',
        onOk: () => {
          goodsCheckResult({ ...params, reason: '' }).then((res) => {
            if (res.success) {
              this.$message.success('通过成功')
              this.getList()
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        onCancel() {},
      })
    },
    async querySpecvalueListBySpecId() {
      const data = await querySpecValueListBySpcId({
        goodsSpecId: this.currentSpecItem.goodsSpecId,
      })
      if (data.code == 200) {
        this.specValueDatas = data.data
      }
    },
    handleSpecvalueOk() {
      this.specValuePage.pageNumber = 1
      this.querySpecvalueListBySpecId()
    },
    handleSpecOk() {
      this.specPage.pageNumber = 1
      this.findCommoditySpecList()
    },
    showModal1(record) {
      this.currentItem = record
      this.specValuePage.pageNumber = 1
      this.findCommoditySpecList()
      this.visible1 = true
    },

    async findCommoditySpecList() {
      var params = {
        page: this.specPage,
        queryParam: this.currentItem.id,
      }
      const data = await querySpecListByCommodityId({
        goodsId: this.currentItem.id,
      })
      if (data.code == 200) {
        this.specDatas = data.data
      }
    },

    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    refreshList() {
      this.selectRowId = []
      this.selectedRowKeys = []
      this.getList()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: {
          isStoreGoods: 1,
          ...this.queryParam,
        },
      }
      getCommodityList(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await commodityDelete(params)
      var text = delFlag == 1 ? '上架' : delFlag == 2 ? '删除' : '下架'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    //添加商品页面路由跳转
    addCommodity() {
      this.$router.push({
        path: '/addCommodity',
      })
    },
  },
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 30px;
  height: 30px;
}
</style>
